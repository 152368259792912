import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container } from "react-bootstrap";

import Support from "../Components/Support/Support";

// features images

import CRM from "../assets/images/crm.png";
import Onboarding from "../assets/images/onboarding.png";
import Tasks from "../assets/images/tasks-page.png";
import DocumentPage from "../assets/images/documents-page.png";
import Billing from "../assets/images/billing.png";

const CrmOverview = () => {
    return (
        <Fragment>
            <CommonBanner
                bannerBg={BannerBG}
                title={
                    <Fragment>
                       <span className="colored-text"> CRM CLIENT OVERVIEW</span>
                    </Fragment>
                }
                description={
                    <Fragment>
                        {/* Streamline your business with our all-in-one solution encompassing CRM, workflows, tasks, onboarding, and billing for seamless operations and enhanced efficiency. */}
                    </Fragment>
                }
                button={false}
                image={CRM}
            />

            <section className={`service-section dark}`} >
                <img className="faq-shape" src={faqShape} alt="" />

                <Container style={{ maxWidth: "1200px" }}>
                    <Col xl={12} className="text-center">
                        <div className="section-title "  data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "black" }} >
                                CRM Client Overview
                            </h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support imgSrc={CRM} desc={"Track prospects, maintain client notes, and prep for annual reviews all through an easy-to-use CRM."} />
                    </Col>
                </Container>
            </section>
            <section className={`service-section dark`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12} className="text-center">
                        <div className="section-title "  data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{color: "white"}}>Workflows</h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={Onboarding}
                            desc={
                                <Fragment>
                                    Make complicated process more simple and
                                    collaborative with workflows.
                                    <ul>
                                        <li>
                                            Standard workflows for onboarding,
                                            account audits, and more.
                                        </li>
                                        <li>
                                            Create custom workflows to meet your
                                            needs.
                                        </li>
                                    </ul>
                                </Fragment>
                            }
                        />
                    </Col>
                </Container>
            </section>
            <section className={`service-section`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12} className="text-center ">
                        <div className="info-title "  data-aos="fade-up" data-aos-delay="300">
                            <h2 className="">Tasks</h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={Tasks}
                            desc={
                                <Fragment>
                                    Keep track of important tasks and easily
                                    collaborate with teammates.
                                </Fragment>
                            }
                        />
                    </Col>
                </Container>
            </section>
            <section className={`service-section gradient`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12} className="text-center ">
                        <div className="info-title "  data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{color: "white"}}>Secure Documents Storage</h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={DocumentPage}
                            desc={
                                <Fragment>
                                    <ul>
                                        <li>
                                            For your firm
                                            <ul>
                                                <li>
                                                    Cloud storage and file management
                                                    for all your firm files.
                                                </li>
                                                <li>
                                                    Easily and inexpensively archive all
                                                    document for compliance purposes.
                                                </li>
                                                <li>
                                                    A centralized system for sharing
                                                    files within your firm.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            For your clients
                                            <ul>
                                                <li>
                                                    Securely share and receive documents
                                                    from clients and prospects.
                                                </li>
                                                <li>
                                                    Integrated with most major
                                                    custodians to automatically
                                                    retrieve, store, and share client
                                                    statements, tax forms, etc.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Fragment>
                            }
                        />
                    </Col>
                </Container>
            </section>
            <section className={`service-section`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12} className="text-center ">
                        <div className="info-title "  data-aos="fade-up" data-aos-delay="300">
                            <h2 className="">Billing</h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={Billing}
                            desc={
                                <Fragment>
                                    We can run your billing or train your staff to use our flexible billing solution that is customizable to any billing methodology. Easily create new billing, pull historical billing, and build forecasts.
                                </Fragment>
                            }
                        />
                    </Col>
                </Container>
            </section>
        </Fragment>
    )
}

export default CrmOverview;