import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/cachetech-logo.svg";
import MobileMenu from "../MobileMenu/MobileMenu";
import React, { useEffect, useRef } from "react";

const Header = () => {
	const headerRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		const handleScroll = () => {
			const header = headerRef.current;
			if (header) {
				if (window.pageYOffset > header.offsetTop) {
					header.classList.add("sticky");
				} else {
					header.classList.remove("sticky");
				}
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<MobileMenu />
			<header id="navbar" ref={headerRef}>
				<Container>
					<Row>
						<Col className="d-flex justify-content-between">
							<div className="site-logo">
								<Link to="/">
									<img src={Logo} alt="logo" />
								</Link>
							</div>
							<div className="main-nav d-flex align-items-center">
								{/* <ul className="d-flex">
									<li className="nav__menu-item nav__menu-item--dropdown">
										<Link className="nav__menu-link" to="/">
											Home
										</Link>
									</li>
									<li className="nav__menu-item nav__menu-item--dropdown">
										<a
											className="nav__menu-link nav__menu-link--dropdown"
											href="#trading"
										>
											Trading
										</a>
										<ul class="nav__dropdown false">
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/rebalancing"}
												>
													Rebalancing
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/tax-loss-harvesting"}
												>
													Tax Loss Harvesting
												</Link>{" "}
											</li>
										</ul>
									</li>
									<li className="nav__menu-item nav__menu-item--dropdown">
										<a
											className="nav__menu-link nav__menu-link--dropdown"
											href="#crm"
										>
											CRM
										</a>
										<ul class="nav__dropdown false">
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/crm-overview"}
												>
													Client Overview
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/tasks-workflows"}
												>
													Tasks & Workflows
												</Link>{" "}
											</li>
										</ul>
									</li>
									<li className="nav__menu-item nav__menu-item--dropdown">
										<a
											className="nav__menu-link nav__menu-link--dropdown"
											href="#account_management"
										>
											Account Management
										</a>
										<ul class="nav__dropdown false">
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/portfolio-audit"}
												>
													Portfolio Audit
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/billing"}
												>
													Billing
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/performance"}
												>
													Performance & Reporting
												</Link>{" "}
											</li>
										</ul>
									</li>
									<li className="nav__menu-item nav__menu-item--dropdown">
										<a
											className="nav__menu-link nav__menu-link--dropdown"
											href="#client_portals"
										>
											Client Portals
										</a>
										<ul class="nav__dropdown false">
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/mobile-app"}
												>
													Mobile App
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/document-storage"}
												>
													Document Storage
												</Link>{" "}
											</li>
											<li>
												{" "}
												<Link
													class="nav__dropdown-item hide-nav"
													to={"/performance"}
												>
													Desktop Portal
												</Link>{" "}
											</li>
										</ul>
									</li>
									<li className="nav__menu-item nav__menu-item--dropdown">
										<Link
											className="nav__menu-link"
											to="/ai-assistant"
										>
											AI Assistant
										</Link>
									</li>
									{/* <li className="nav__menu-item nav__menu-item--dropdown">
										<a className="nav__menu-link" href="pricing">
											Pricing
										</a>
									</li>
								</ul> */}
								<a
									href="#contact-us"
									className="bordered-btn"
									onClick={(evt) => {
										const footer =
											document.getElementById("footer");
										footer.scrollIntoView({
											behavior: "smooth",
										});
									}}
								>
									Contact us
								</a>
							</div>
						</Col>
					</Row>
				</Container>
			</header>
		</>
	);
};

export default Header;
