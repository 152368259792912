import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import CRM from "../assets/images/crm.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container } from "react-bootstrap";

import DocumentPage from "../assets/images/documents-page.png";

import ModalImage from "react-modal-image";

const DocumentStorage = () => {
	return (
		<Fragment>
			<CommonBanner
				bannerBg={BannerBG}
				title={
					<Fragment>
						<span className="colored-text">
							SECURE DOCUMENTS STORAGE
						</span>
					</Fragment>
				}
				description={""}
				button={false}
				image={DocumentPage}
			/>

			<section className={`service-section dark}`}>
				<img className="faq-shape" src={faqShape} alt="" />

				<Container style={{ maxWidth: "1200px" }}>
					<Col xl={12} className="text-center">
						<div
							className="section-title "
							data-aos="fade-up"
							data-aos-delay="300"
						>
							<h2 style={{ color: "black" }}>
								<span className="colored-text">
									Secure Documents Storage
								</span>
							</h2>
						</div>
					</Col>
					<Col xl={12}>
						<div
							className="info-title "
							data-aos="fade-up"
							data-aos-delay="300"
						>
							<ul>
								<li>
									For your firm
									<ul>
										<li>
											Cloud storage and file management
											for all your firm files.
										</li>
										<li>
											Easily and inexpensively archive all
											documents for compliance purposes.
										</li>
										<li>
											A centralized system for sharing
											files within your firm.
										</li>
									</ul>
								</li>
								<li>
									For your clients
									<ul>
										<li>
											Securely share and receive documents
											from clients and prospects.
										</li>
										<li>
											Integrated with most major
											custodians to automatically
											retrieve, store, and share client
											statements, tax forms, etc.
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</Col>
					<Col xl={12}>
						<ModalImage
							small={DocumentPage}
							medium={DocumentPage}
							// alt={description}
							className="modal-image"
						/>
					</Col>
				</Container>
			</section>
		</Fragment>
	);
};

export default DocumentStorage;
