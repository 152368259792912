import React, { useEffect, useState, useRef } from "react";

import Banner from "../Components/Banner/Banner";
import Services from "../Components/Services/Services";
import Wheel from "../Components/Wheel/Wheel";
import MobileAppDemo from "../assets/images/mobile-app-demo.png";
import WebAppDemo from "../assets/images/web-app-demo.png";
import Tasks from "../assets/images/tasks-page.png";
import Billing from "../assets/images/billing.png";
import CaitVideo from "../assets/videos/cait-demo.mp4";
import IPSVideo from "../assets/videos/cait-ips-demo.mp4";
import DocumentsVideo from "../assets/videos/cait-documents-demo.mp4";
import Performance from "../assets/images/hh-performance.png";
import DocumentPage from "../assets/images/documents-page.png";
import CRM from "../assets/images/crm.png";
import SecuritiesPage from "../assets/images/securities.png";
import ModelPerformance from "../assets/images/model-performance.png";
import Onboarding from "../assets/images/onboarding.png";
import ManualReview from "../assets/images/manual-review-page.png";
import ModeTrade from "../assets/images/model-trade.png";
import Restrictions from "../assets/images/restrictions-page.png";
import AccountTax from "../assets/images/account-tax.png";
import TeamPicture from "../assets/images/team-picture.jpg";

const HomePage = () => {
	const [activeTab, setActiveTab] = useState(null);
	const [displayHoverButton, setDisplayHoverButton] = useState(false);

	const ref1 = useRef();
	const ref2 = useRef();
	const ref3 = useRef();
	const ref4 = useRef();
	const ref5 = useRef();

	useEffect(() => {}, [activeTab]);

	useEffect(() => {
		const handleScroll = () => {
			if (isScrollingAndPassedNavbar() && !isInTheFooterSection()) {
				setDisplayHoverButton(true);
			} else {
				setDisplayHoverButton(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const isScrollingAndPassedNavbar = () => {
		const navbar = document.getElementById("navbar");
		if (!navbar) return false;
		return window.scrollY > navbar.clientHeight;
	};

	const isInTheFooterSection = () => {
		const footer = document.getElementById("footer");
		if (!footer) return false;
		return window.scrollY > footer.offsetTop - 300;
	};

	const scrollToViewRef = (ref) => {
		if (!ref?.current) return;
		//
		ref.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	};

	const handleClickTab = (tabName) => {
		const isMobile = window.innerWidth < 991;
		if (isMobile) return;
		setActiveTab(tabName);
		if (tabName === "ai") {
			scrollToViewRef(ref1);
		} else if (["crm", "tasks", "secure-doc"].includes(tabName)) {
			scrollToViewRef(ref2);
		} else if (tabName === "client-portal") {
			scrollToViewRef(ref3);
		} else if (tabName === "performance") {
			scrollToViewRef(ref4);
		} else if (["portfolio", "trading", "tax"].includes(tabName)) {
			scrollToViewRef(ref5);
		}
	};

	return (
		<>
			<Banner />
			<Wheel onClick={handleClickTab} />
			<Services
				innerRef={ref1}
				sectionName="ai"
				title={
					<>
						INTRODUCING <span className="colored-text"> CAIT </span>
						YOUR PERSONAL
						<br />
						AI ADVISOR SUPPORT
					</>
				}
				description="
					At the core of our solution is Cait, your
					personal AI advisor support. Cait enables
					users to engage with data in a
					conversational manner, including client
					information, account balances, transactions,
					and investment performance. This intuitive
					AI interface enhances efficiency and allows
					advisors to focus on building strong client
					relationships and delivering personalized
					service.
				"
				tabs={[
					{
						id: 1,
						title: "Your Personal AI Advisor Support",
						video: CaitVideo,
					},
					{
						id: 2,
						title: "Investment Policy Statement by CAIT",
						video: IPSVideo,
					},
					{
						id: 2,
						title: "Document processing and insights from CAIT", // Video demonstrating document processing and insights from CAIT
						video: DocumentsVideo,
					},
				]}
				activeTab={activeTab}
				bgColor="dark"
			/>
			<Services
				innerRef={ref2}
				sectionName="crm"
				title={<>CRM, WORKFLOWS, TASKS, ONBOARDING, AND BILLING</>}
				description=""
				tabs={[
					{
						id: 1,
						title: "CRM", // Household page
						imgSrc: CRM,
						description: `
							Track prospects, maintain client
							notes, and prep for annual reviews
							all through an easy-to-use CRM.
						`,
					},
					{
						id: 2,
						title: "Workflows",
						imgSrc: Onboarding,
						description: (
							<>
								Make complicated process more simple and
								collaborative with workflows.
								<ul>
									<li>
										Standard workflows for onboarding,
										account audits, and more.
									</li>
									<li>
										Create custom workflows to meet your
										needs.
									</li>
								</ul>
							</>
						),
					},
					{
						id: 2,
						title: "Tasks", // Account audit page and Tasks page
						imgSrc: Tasks,
						description: (
							<>
								Keep track of important tasks and easily
								collaborate with teammates.
							</>
						),
					},
					{
						id: 3,
						title: "Secure Documents Storage", // Documents page (video) and demo client documents
						imgSrc: DocumentPage,
						description: (
							<ul>
								<li>
									For your firm
									<ul>
										<li>
											Cloud storage and file management
											for all your firm files.
										</li>
										<li>
											Easily and inexpensively archive all
											document for compliance purposes.
										</li>
										<li>
											A centralized system for sharing
											files within your firm.
										</li>
									</ul>
								</li>
								<li>
									For your clients
									<ul>
										<li>
											Securely share and receive documents
											from clients and prospects.
										</li>
										<li>
											Integrated with most major
											custodians to automatically
											retrieve, store, and share client
											statements, tax forms, etc.
										</li>
									</ul>
								</li>
							</ul>
						),
					},
					{
						id: 4,
						title: "Billing", // Billing page
						imgSrc: Billing,
						description: `
							We can run your billing or train your staff to use our flexible billing solution that is customizable to any billing methodology. Easily create new billing, pull historical billing, and build forecasts. 
						`,
					},
				]}
				bgColor="gradient"
				activeTab={activeTab}
			/>
			<Services
				innerRef={ref3}
				sectionName="client-portal"
				title={
					<>
						<span className="colored-text">
							FULLY WHITE LABELED{" "}
						</span>
						CLIENT PORTAL ANDROID, IOS AND WEB
					</>
				}
				description=""
				tabs={[
					{
						id: 1,
						title: "Mobile App",
						imgSrc: MobileAppDemo,
					},
					{
						id: 2,
						title: "Web App",
						imgSrc: WebAppDemo,
					},
				]}
				bgColor="light"
				activeTab={activeTab}
			/>
			<Services
				innerRef={ref4}
				sectionName="performance"
				title={<>PERFORMANCE AND REPORTING</>}
				description="
					Quickly access performance and reporting for individual accounts, households and models. Create custom reports and dashboards to meet your needs.
				"
				tabs={[
					{
						id: 1,
						title: "Client Performance",
						imgSrc: Performance, // Household performance and model performance
					},
					{
						id: 1,
						title: "Model Performance",
						imgSrc: ModelPerformance, // Household performance and model performance
					},
					{
						id: 2,
						title: "Reporting",
						imgSrc: SecuritiesPage, // Security page task report page
					},
				]}
				activeTab={activeTab}
				bgColor="gradient"
			/>
			<Services
				innerRef={ref5}
				sectionName="portfolio"
				title={
					<>
						<span className="colored-text">
							PORTFOLIO MANAGEMENT{" "}
						</span>
						AND TRADING
					</>
				}
				description="
					Mix and match models in accounts. Use our models and/or create your own.
					Great for optimizing asset location for tax efficiency across a household.
					Set a max tax impact to aid in transitioning a client from an undesired allocation.
				"
				tabs={[
					{
						id: 1,
						title: "CacheTech Models", // Team picture
						imgSrc: TeamPicture,
						description: (
							<>
								<ul>
									<li>
										Passive and tactical models managed by
										our highly experienced investment
										management team at no additional charge.
									</li>
									<li>
										We offer model portfolios spanning the
										risk spectrum from conservative to
										aggressive.
									</li>
									<li>
										Multi asset class models are available
										as well as single asset class models.
										<ul>
											<li>
												Add global diversification,
												individual stock selection,
												income generation, and more to
												your client accounts.
											</li>
										</ul>
									</li>
									<li>
										Not an all or nothing approach. You can
										use our models alongside your own in the
										same account.
									</li>
								</ul>
							</>
						),
					},
					{
						id: 2,
						title: "Your Custom Models", // Model trading page
						imgSrc: ModeTrade,
						description: (
							<>
								<ul>
									<li>
										Create your own models comprised of
										ETFs, mutual funds, and individual
										stocks.
									</li>
									<li>
										Easily add alternative securities for
										tax loss harvesting and client
										restrictions.
									</li>
									<li>
										Set customized rebalancing rules and
										thresholds.
									</li>
									<li>
										Analytics to provide insight into
										sector, global, and factor exposure.
									</li>
								</ul>
							</>
						),
					},
					{
						id: 3,
						title: "Trading", // Manual Review, Scheduled Trades in the account page, full book trading
						imgSrc: ManualReview,
						description: (
							<>
								<ul>
									<li>
										You may utilize our trading team at no
										additional cost or run trading yourself.
									</li>
									<li>
										Fast and scalable allowing frequent
										rebalancing and tax loss harvesting.
										<ul>
											<li>
												Advanced cloud infrastructure
												and distributed computing leads
												to very fast trading at any
												scale.
											</li>
										</ul>
									</li>
								</ul>
							</>
						),
					},
					{
						id: 3,
						title: "Sophisticated Restrictions", // Restrictions page on the account
						imgSrc: Restrictions,
						description: (
							<>
								Sophisticated restriction handling.
								<ul>
									<li>
										Restrict individual securities, sectors,
										asset classes, and more.
									</li>
									<li>
										Create a cash minimum restriction that
										ensures a minimum cash balance either
										permanently or just in time for periodic
										distributions.
									</li>
									<li>
										Set a max tax impact to aid in
										transitioning a client from an undesired
										allocation.
									</li>
								</ul>
							</>
						),
					},
					{
						id: 4,
						title: "Tax Loss Harvesting", // Tax lots page on the account and the short term gain/loss from expected trades
						imgSrc: AccountTax,
						description: (
							<>
								<ul>
									<li>
										Generate tax-alpha in your client
										accounts utilizing CacheTech's fully
										automated, advanced tax algorithms.
										<ul>
											<li>
												Tax loss harvesting is standard
												in all models, including your
												own custom models.
											</li>
											<li>
												High-frequency, lot-level
												monitoring to maximize
												tax-alpha.
											</li>
											<li>
												Advanced cross account washsale
												detection.
											</li>
										</ul>
									</li>
								</ul>
							</>
						),
					},
				]}
				bgColor="light"
				activeTab={activeTab}
			/>
			{displayHoverButton && (
				<button
					className="hover-button"
					onClick={(evt) => {
						evt.preventDefault();
						// Navigate to contact form in the footer
						const footer = document.querySelector("#footer");
						footer.scrollIntoView({ behavior: "smooth" });
					}}
				>
					Contact us
				</button>
			)}
		</>
	);
};

export default HomePage;
