import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";

const CommonBanner = ({
	height = 100,
	bannerBg,
	title,
	description,
	button,
	image,
	buttonTitle,
	buttonClick,
}) => {
	const ref = useRef(null);
	useEffect(() => {
		// Set the value of the CSS variable
	}, [height, ref.current]);

	return (
		<section className="section-banner" ref={ref}>
			<div className="tilt-banner">
				<ul class="circles">
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</ul>
			</div>
			<Container>
				<Row className="align-items-center">
					<Col xl={6} lg={6}>
						<div
							className="banner-content "
							data-aos="fade-left"
							data-aos-delay="300"
						>
							<h1>{title}</h1>
							<p>{description}</p>
							{/*{
                                button && (
                                    <div className="banner-buttons">
                                        <a
                                            href="#contact-us"
                                            className="primary-btn"
                                            onClick={(evt) => buttonClick(evt)}
                                        >
                                            {buttonTitle}
                                        </a>
                                    </div>
                                )
                            } */}
						</div>
					</Col>
					{image && (
						<Col xl={6} lg={6}>
							<div className="banner-thumbnail wow fadeInUp">
								<img
									src={image}
									alt=""
									style={{ borderRadius: "10px" }}
								/>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export default CommonBanner;
