import { Col, Container, Row } from "react-bootstrap";
import CRM from "../../assets/images/crm.png";
import BannerBG from "./images/banner-bg.png";

const Banner = () => {
	return (
		<section
			className="section-banner"
			style={{ backgroundImage: `url(${BannerBG})` }}
		>
			<Container>
				<Row className="align-items-center">
					<Col xl={6} lg={6}>
						<div className="banner-content wow fadeInLeft">
							<h1>
								BUILT BY{" "}
								<span className="colored-text">ADVISORS</span>{" "}
								FOR ADVISORS
							</h1>
							<p>
								CacheTech avoids the FinTech cliche of being all
								Tech and no Fin. Our platform was crafted by
								experienced advisors{" "}
								<span className="colored-text">
									for advisors.{" "}
								</span>
								More than just software, you can use CacheTech
								and its experienced team to handle firm
								operations, compliance, investment management,
								trading and more.
							</p>
							<div className="banner-buttons">
								<a
									href="#contact-us"
									className="primary-btn"
									onClick={(evt) => {
										// evt.preventDefault();
										const footer =
											document.querySelector("#footer");
										footer.scrollIntoView({
											behavior: "smooth",
										});
									}}
								>
									Schedule a Demo
								</a>
							</div>
						</div>
					</Col>
					<Col xl={6} lg={6}>
						<div className="banner-thumbnail wow fadeInUp">
							<img
								src={CRM}
								alt=""
								style={{ borderRadius: "10px" }}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Banner;
