import React, { useEffect, useState } from "react";

import ModalImage from "react-modal-image";
import ReactPlayer from "react-player";

import { Tabs, Tab, Row, Col } from "react-bootstrap";
import Support from "../Support/Support";

const AutoChangingTab = ({
	tabs = [],
	sectionName,
	activeTabName = "",
	bgColor,
}) => {
	const [activeTab, setActiveTab] = useState(0);

	useEffect(() => {
		if (!activeTabName) return;
		if (sectionName === "crm") {
			if (activeTabName === "tasks") {
				setActiveTab(1);
			} else if (activeTabName === "secure-doc") {
				setActiveTab(3);
			} else setActiveTab(0);
		} else if (sectionName === "client-portal") {
			setActiveTab(0);
		} else if (sectionName === "performance") {
			setActiveTab(0);
		} else if (sectionName === "portfolio") {
			if (activeTabName === "trading") {
				setActiveTab(2);
			} else if (activeTabName === "tax") {
				setActiveTab(3);
			} else setActiveTab(0);
		}
	}, [activeTabName]);

	const handleTabSelect = (selectedTab) => {
		setActiveTab(selectedTab);
	};

	const renderTabContent = (tab) => {
		const { video, imgSrc, description } = tab;
		if (video) {
			return (
				<ReactPlayer
					url={video}
					muted={true}
					playing={true}
					loop={true}
					width="calc(100%)"
					height="calc(100%)"
					style={{ width: "fit-content" }}
					controls={true}
				/>
			);
		} else if (imgSrc && description) {
			return <Support imgSrc={imgSrc} desc={description} />;
		}
		return (
			<ModalImage
				small={imgSrc}
				medium={imgSrc}
				// alt={description}
				className="modal-image"
			/>
		);
	};

	return (
		<section className="tab-video-section">
			<Row>
				<Col style={{ zIndex: 0 }}>
					<div className="tabs-wrapper">
						<Tabs activeKey={activeTab} onSelect={handleTabSelect}>
							{tabs.map((tab, index) => (
								<Tab
									key={index}
									eventKey={index}
									title={tab.title}
									style={{ width: "100%" }}
								>
									{renderTabContent(tab)}
								</Tab>
							))}
						</Tabs>
					</div>
				</Col>
			</Row>
		</section>
	);
};

export default AutoChangingTab;
