import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import CRM from "../assets/images/crm.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container, Row } from "react-bootstrap";
import AccountTax from "../assets/images/account-tax.png";
import ModalImage from "react-modal-image";

const TaxLossHarvesting = () => {
    return (
        <Fragment>
            <CommonBanner
                bannerBg={BannerBG}
                title={
                    <Fragment>
                        <span className="colored-text"> TAX LOSS HARVESTING </span>
                    </Fragment>
                }
                description={""}
                button={false}
                image={AccountTax}
            />

            <section className={`service-section`}   data-aos="fade-up" data-aos-delay="300">
                <img className="faq-shape" src={faqShape} alt="" />

                <Container style={{ maxWidth: "1200px" }}>
                    <Col xl={12} className="text-center">
                        <div className="section-title"  data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "black" }} >
                                <span className="colored-text"> Tax Loss Harvesting </span>
                            </h2>
                            <p style={{ color: "black" }} >
                                Leverage CacheTech's automated tax algorithms for tax-alpha generation in client accounts. Standard tax loss harvesting across all models, including custom ones. Maximize tax-alpha with high-frequency, lot-level monitoring and advanced cross-account wash sale detection.
                            </p>
                        </div>
                    </Col>
                    <Row className="pb-5">
                        <Col xl={12}>
                            <ModalImage
                                small={AccountTax}
                                medium={AccountTax}
                                alt={<>
                                    <ul>
                                        <li>
                                            Generate tax-alpha in your client
                                            accounts utilizing CacheTech's fully
                                            automated, advanced tax algorithms.
                                            <ul>
                                                <li>
                                                    Tax loss harvesting is standard
                                                    in all models, including your
                                                    own custom models.
                                                </li>
                                                <li>
                                                    High-frequency, lot-level
                                                    monitoring to maximize
                                                    tax-alpha.
                                                </li>
                                                <li>
                                                    Advanced cross account washsale
                                                    detection.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </>}
                                className="modal-image"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default TaxLossHarvesting;