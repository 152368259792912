import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import CRM from "../assets/images/crm.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container, Row, Table } from "react-bootstrap";

const PrivacyPolicy = () => {
	return (
		<Fragment>
			<CommonBanner
				bannerBg={BannerBG}
				title={
					<Fragment>
						<span className="colored-text"> Privacy Policy </span>
					</Fragment>
				}
				description={""}
				button={false}
			/>

			<section
				className={`service-section privacy-policy-section`}
				data-aos="fade-up"
				data-aos-delay="300"
			>
				<img className="faq-shape" src={faqShape} alt="" />

				<Container style={{ maxWidth: "1200px" }}>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<span className="">Last Revised: 2/1/2024</span>
							</h4>
							<p style={{ color: "black" }}>
								Your privacy is important to CacheTech Inc.{" "}
								<strong>
									("CacheTech", "we", "us", or "our")
								</strong>
								. This CacheTech Privacy Policy{" "}
								<strong>("Privacy Policy")</strong> describes
								how CacheTech may collect, use, store, disclose,
								process, and transfer your personal information,
								through your access to or use of CacheTech
								products and services, including those at{" "}
								<a href="www.cachetech.io" target="_blank">
									www.cachetech.io
								</a>
								, subdomains, any website of ours that links to
								this Privacy Policy, related websites, mobile
								software applications, and other offerings
								<strong>(collectively, the "Services")</strong>.
								By using these Services, you signify your
								acceptance of this Privacy Policy. If you do not
								agree to this Privacy Policy, please do not use
								the Services. CacheTech may change this Privacy
								Policy from time to time by posting changes at{" "}
								<a
									href="www.cachetech.io/privacy-policy"
									target="_blank"
								>
									www.cachetech.io/privacy-policy
								</a>{" "}
								and may send notification emails regarding such
								changes. Your continued use of the Services
								following the posting of such changes will be
								deemed your acceptance of those changes.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document0">
									Privacy Policy Applicability
								</a>
							</h4>
							<p style={{ color: "black" }}>
								This Privacy Policy applies when you access the
								Services or share information with CacheTech.
								CacheTech may revise this Privacy Policy at any
								time <a href="#document0">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document1">
									Information We Collect from You
								</a>
							</h4>
							<p style={{ color: "black" }}>
								We collect all information that you provide
								including information you provide via the
								Services, your devices (e.g., computers,
								smartphones, and tablets), telephone and email
								as well as information we receive from partners
								(like your financial advisor) and through using
								cookies and other such technologies.{" "}
								<a href="#document1">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document2">
									How We Use Your Information
								</a>
							</h4>
							<p style={{ color: "black" }}>
								We process your personal information only with
								your consent or as needed to provide you the
								Services. We may also use your personal
								information to comply with legal obligations,
								operate our business, protect the vital
								interests of you, our customers, or the public,
								or for other legitimate business interests of
								CacheTech. <a href="#document2">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document3">
									How We Share Your Information
								</a>
							</h4>
							<p style={{ color: "black" }}>
								We share your information with our partners,
								service providers, contractors, agents, and
								thirdparty vendors as they need it to fulfill
								the Services or your subscription to the
								Services or for other requests you make of
								CacheTech. We may also share your information to
								comply with law or for other legitimate business
								interests. <a href="#document3">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document4">
									How We Store and Secure Your Information
								</a>
							</h4>
							<p style={{ color: "black" }}>
								CacheTech may store your information in any
								country where CacheTech or its affiliates,
								partners, or providers operate facilities, and
								we will retain your information as long as
								necessary for the purposes outlined in this
								Privacy Policy or for so long as permitted by
								law, whichever is shorter. CacheTech takes
								technological and organizational measures
								designed to protect your personal information
								against loss, theft, and unauthorized access,
								use, disclosure, or modification.{" "}
								<a href="#document4">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document5">
									Accessing and Updating Your Information
								</a>
							</h4>
							<p style={{ color: "black" }}>
								You can access, get copies of, delete, change,
								or correct your personal information, or request
								changes to our use of your personal information
								by, using the contact information below. You may
								have additional rights based on where you
								reside. <a href="#document5">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document6">
									Additional Privacy Information
								</a>
							</h4>
							<p style={{ color: "black" }}>
								CacheTech’s databases are located in the United
								States, and you consent to the transfer or your
								personal information from your location to the
								United States. The Services are not for use by
								children under the age of 16 years old or the
								lowest age permitted by applicable law.
								CacheTech will take all reasonable steps
								necessary to protect the privacy and safety of
								any child. You may have additional rights under
								applicable law. If you have any questions about
								this privacy policy, please contact us via the
								information below. <a href="#document6">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>
								<a href="#document7">
									European Union, United Kingdom, and Swiss
									Data Subject Rights
								</a>
							</h4>
							<p style={{ color: "black" }}>
								If you are an EU, UK, or Swiss resident, you may
								have additional rights under applicable privacy
								laws and regulations.{" "}
								<a href="#document7">More</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title" id="document0">
							<h4 style={{ color: "black" }}>
								Privacy Policy Applicability
							</h4>
							<p style={{ color: "black" }}>
								This Privacy Policy applies when you access or
								use the Services or share information with
								CacheTech. By accessing or using the Services
								and/or opting into this Privacy Policy (as
								applicable in your jurisdiction), you consent to
								this Privacy Policy. CacheTech may update this
								Privacy Policy at any time. If you do not
								consent to this Privacy Policy for any reason,
								please do not access or use the Services or
								share your information with us. Please note that
								this Privacy Policy applies only to the Services
								and not to any other third-party website linked
								to or from it, or any third-party website in
								which CacheTech’s content or functionality is
								embedded. We do not control the privacy policies
								or practices of others, including your financial
								advisors.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }} id="document1">
								Information We Collect from You
							</h4>
							<p style={{ color: "black" }}>
								In general, we receive and collect all
								information you provide via the Services,
								including through website input fields
								(including advertisements), phone, email
								(including email campaigns), web chat, or other
								such ways. This includes personal information
								that can identify or relates to you, including,
								but not limited to:
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<Table striped bordered hover responsive>
							<thead>
								<tr>
									<th>Category of Personal Information</th>
									<th>
										Examples of Personal Information We
										Collect
									</th>
									<th>
										Categories of Sources from which such
										Personal Information is Collected
									</th>
									<th>
										Categories of Third Parties or Services
										Providers with whom we share such
										Personal Information
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Profile or Contact Data</td>
									<td>
										Examples may include:
										<ul>
											<li> First and last name </li>
											<li> Postal address </li>
											<li> Telephone number </li>
											<li> Email address </li>
										</ul>
									</td>
									<td>
										<ul>
											<li> You </li>
											<li> Service providers </li>
											<li>
												{" "}
												Other independent third-party
												sources{" "}
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li> Data analytics providers </li>
											<li> Service providers </li>
											<li> Third-party vendors </li>
											<li>
												{" "}
												Partners you authorize, access,
												or authenticate{" "}
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Demographic Data</td>
									<td>
										Examples may include:
										<ul>
											<li> Age </li>
											<li> Birthday </li>
											<li> Zip code </li>
											<li> Gender </li>
											<li> National origin </li>
											<li> Marital status </li>
											<li> Disability </li>
										</ul>
									</td>
									<td>
										<ul>
											<li> You </li>
											<li> Service providers </li>
											<li>
												{" "}
												Other independent third-party
												sources{" "}
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li> Data analytics providers </li>
											<li> Service providers </li>
											<li> Third-party vendors </li>
											<li>
												{" "}
												Partners you authorize, access,
												or authenticate{" "}
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Personal Identifiers</td>
									<td>
										Examples may include:
										<ul>
											<li> Social security number </li>
											<li>Passport number </li>
											<li> Driver’s license </li>
										</ul>
									</td>
									<td>
										<ul>
											<li> You </li>
											<li> Service providers </li>
											<li>
												{" "}
												Other independent third-party
												sources{" "}
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li> Data analytics providers </li>
											<li> Service providers </li>
											<li> Third-party vendors </li>
											<li>
												{" "}
												Partners you authorize, access,
												or authenticate{" "}
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Commercial Data</td>
									<td>
										Examples may include:
										<ul>
											<li>Consumer profiles</li>
											<li>
												Records of products or services
												sold, used, or purchased
											</li>
											<li>
												Other purchasing histories or
												tendencies
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>You</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>Data analytics providers</li>
											<li>Service providers</li>
											<li>Third-party vendors</li>
											<li>
												Partners you authorize, access,
												or authenticate
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Device/IP Data</td>
									<td>
										Examples may include:
										<ul>
											<li>IP address</li>
											<li>Device ID</li>
											<li>Domain server</li>
											<li>Browser type</li>
											<li>Search history</li>
											<li>Type of device</li>
											<li>
												Information regarding your
												interaction with the Services or
												any advertisement
											</li>
											<li>
												Information collected
												automatically through cookies
												and similar technology, device
												type, domain names, and access
												time/logs
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>You</li>
											<li>Service providers</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>Advertising networks</li>
											<li>Data analytics providers</li>
											<li>Service providers</li>
											<li>Third-party vendors</li>
											<li>
												Partners you authorize, access,
												or authenticate
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Geolocation Data</td>
									<td>
										Examples may include:
										<ul>
											<li>General physical location</li>
											<li>Precise physical location</li>
											<li>Movements</li>
											<li>
												IP-address-based location
												information
											</li>
											<li>Cookie ID</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>You</li>
											<li>Service providers</li>
										</ul>
									</td>
									<td>We do not share this information.</td>
								</tr>
								<tr>
									<td>Web Analytics</td>
									<td>
										Examples may include:
										<ul>
											<li>Web page interactions</li>
											<li>
												Referring webpage/source through
												which you accessed the Services
											</li>
											<li>
												Browsing activity, including the
												addresses of websites that you
												visit
											</li>
											<li>
												Non-identifiable request IDs
											</li>
											<li>
												Statistics associated with the
												interaction between device or
												browser and the Services
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>You</li>
											<li>Service providers</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>Advertising networks</li>
											<li>Data analytics providers</li>
											<li>Service providers</li>
											<li>Third-party vendors</li>
											<li>
												Partners you authorize, access,
												or authenticate
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Personal Health Information</td>
									<td>
										Examples may include (whether written,
										oral, or electronic):
										<ul>
											<li>Insurance information</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>You</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>Third-party vendors</li>
											<li>
												Partners you authorize, access,
												or authenticate
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Financial and Payment Data</td>
									<td>
										Examples may include:
										<ul>
											<li>Billing address</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>You</li>
											<li>Service providers</li>
											<li>
												CacheTech’s affiliated entities,
												partners, etc.
											</li>
											<li>
												Other independent third-party
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>Service providers</li>
											<li>Third-party vendors</li>
											<li>
												Partners you authorize, access,
												or authenticate
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Sensory Data</td>
									<td>
										Examples may include:
										<ul>
											<li>Photos</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>You</li>
											<li>
												CacheTech’s affiliated entities,
												partners, etc.
											</li>
										</ul>
									</td>
									<td>
										<ul>
											<li>Advertising networks</li>
											<li>Third-party vendors</li>
											<li>
												Partners you authorize, access,
												or authenticate
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								You have the choice on what information to share
								and the Services you want to engage. You can
								choose not to provide information to us, but in
								general, some information about you is required
								in order for you to access certain
								functionalities of the Services. We also may
								periodically obtain information about you from
								affiliated entities, partners, and other
								independent third-party sources, like LinkedIn,
								and will add it to our database of information,
								including any information that you provide to
								third parties that are associated with or whose
								products and/or services are integrated with the
								Services.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								Additionally, you may provide personal
								information to us to facilitate your access to
								and interaction with a third-party provider,
								such as such as your financial advisors (each, a{" "}
								<strong>“CacheTech Customer”</strong>). If you
								direct us to provide personal information to
								such CacheTech Customer, any usage of that
								information will be subject to the agreement(s)
								in place between you and such CacheTech
								Customer.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								We may use cookies, log files, and similar
								tracking technologies, including those from
								third-party service providers like Amazon Web
								Services, AWS Cognito, Google Analytics,
								HubSpot, and other cloud-based tools, to
								automatically collect your preferences,
								performance data, and information about your web
								usage when you visit the Services. For example,
								we may collect your IP address, device and
								Internet service provider information, and Web
								browser details. We may also collect information
								about your online activity, such as pages viewed
								and interactions with other users. This may
								include collecting geolocation signals from your
								IP address or device settings to determine your
								location. By collecting and using this
								information, we may operate and personalize the
								Services for you, including to provide more
								relevant ads and search results. For more
								information on how we use cookies, please see
								the “Cookies” section below.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								The Services are not designed to recognize or
								respond to “do not track” signals received from
								browsers. You can control the information
								collected by such tracking technologies or be
								alerted when cookies are sent by adjusting the
								settings on your Internet browser or devices,
								but such adjustments may affect or disable
								certain functionality of the Services. You can
								learn more about Google Analytics and your
								options for opting out of interest-based Google
								ads at
								<a
									href="https://adssettings.google.com"
									target="_blank"
									rel="noreferrer"
								>
									https://adssettings.google.com
								</a>
								. You can learn more about targeted ads and your
								ability to opt out of receiving interest-based
								ads at{" "}
								<a
									href="https://optout.aboutads.info"
									target="_blank"
									rel="noreferrer"
								>
									https://optout.aboutads.info
								</a>{" "}
								and
								<a
									href="www.networkadvertising.org/choices"
									target="_blank"
									rel="noreferrer"
								>
									www.networkadvertising.org/choices
								</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>Cookies</h4>
							<p style={{ color: "black" }}>
								Cookies, also known as tracking cookies or
								browser cookies, and similar technologies such
								as web beacons, clear GIFs, pixel tags, and
								JavaScript (collectively, “Cookies”) are small
								pieces of data, usually text files, placed on a
								computer, tablet, phone, or similar device when
								you use that device to access the Services. We
								use the following types of Cookies:
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<ul>
								<li>
									<strong>Essential Cookies:</strong>{" "}
									Essential Cookies are necessary for
									providing you with services and features
									that you requested. For example, these
									Cookies allow you to log into and stay
									logged into secure areas of the Services,
									save language preferences, and more. These
									Cookies are required to make the Services
									available to you, so they cannot be
									disabled.
								</li>
								<li>
									<strong>Functional Cookies:</strong>{" "}
									Functional Cookies are utilized to record
									your choices and settings, maintain your
									preferences over time, and recognize you
									when you return to our Services. These
									Cookies help us personalize our content for
									you, greet you by name, and remember your
									preferences. Some examples include your
									ability to comment on a blog, facilitate web
									chat services, update user preferences, and
									more.
								</li>
								<li>
									<strong>
										Performance/Analytical Cookies:
									</strong>{" "}
									Performance/Analytical Cookies allow us to
									understand how visitors (like you) use our
									Services. These Cookies accomplish this by
									collecting information about the number of
									visitors to the Services, what pages
									visitors view the most, and how long
									visitors view specific pages. These Cookies
									also help us measure and manage the
									performance of our advertising campaigns in
									order to help us improve the campaigns and
									the Services’ content. For example, Google
									LLC (“Google”) uses cookies in connection
									with its Google Analytics services. Google’s
									ability to use and share information
									collected by Google Analytics about your
									visits to the Services is subject to the
									Google Analytics Terms of Use and the Google
									Privacy Policy. You have the option to
									opt-out of Google’s use of Cookies by
									visiting the Google advertising opt-out page
									at{" "}
									<a
										href="http://www.google.com/privacy_ads.html"
										target="_blank"
										rel="noreferrer"
									>
										http://www.google.com/privacy_ads.html
									</a>{" "}
									or the Google Analytics Opt-out Browser
									Addon at{" "}
									<a
										href="https://tools.google.com/dlpage/gaoptout/"
										target="_blank"
										rel="noreferrer"
									>
										https://tools.google.com/dlpage/gaoptout/
									</a>
									.
								</li>
							</ul>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								You have the option to decide through your
								internet browser’s settings whether or not to
								accept Cookies. Most browsers allow users to
								choose whether to turn off the Cookie feature,
								which will prevent your browser from accepting
								new Cookies, as well as (depending on the
								browser) allow you to toggle whether to accept
								each new Cookie. You can also clear all Cookies
								that are already on your device. If you do this,
								however, you may have to manually adjust some
								preferences every time you visit the Services,
								and some functionalities may not work.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								To explore what Cookie settings are available to
								you, look in the “settings”, “preferences” or
								“options” section of your browser’s menu. To
								find out more information about Cookies,
								including information about how to manage and
								delete Cookies generally, please visit{" "}
								<a
									href="http://www.allaboutcookies.org/"
									target="_blank"
									rel="noreferrer"
								>
									http://www.allaboutcookies.org/
								</a>
								.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }} id="document2">
								How We Use Your Information
							</h4>
							<p style={{ color: "black" }}>
								We process your personal information with your
								consent or as needed to provide you the
								Services. We may also use your personal
								information to comply with legal obligations,
								operate our business, protect the vital
								interests of you, our customers, or the public,
								or for other legitimate business interests of
								CacheTech as described in this Privacy Policy.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								More specifically, we may use your personal
								information to
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<ul>
								<li>
									<strong>
										Optimize and improve the Services:
									</strong>{" "}
									We continually try to improve the Services
									based on the information and feedback we
									receive from you and our other users,
									including by optimizing the content on or
									functionalities of the Services
								</li>
								<li>
									<strong>
										Personalize the user experience:
									</strong>{" "}
									We may use your information to measure
									engagement with the Services and to
									understand how you and our other users
									interact with and use the Services and other
									resources we provide.
								</li>
								<li>
									<strong>Improve customer service:</strong>{" "}
									Your information helps us to more
									effectively develop the Services and respond
									to your support needs.
								</li>
								<li>
									<strong>Process transactions:</strong> We
									may use the information you provide to
									fulfill your requests. We do not share this
									information with outside parties except to
									the extent necessary to provide the Services
									and related activities.
								</li>
								<li>
									<strong>
										To send periodic communications:
									</strong>{" "}
									The communication methods you provide
									through our contact forms will be used to
									send information and updates pertaining to
									the Services. It may also be used to respond
									to your inquiries or other requests. If you
									opt into our mailing list, you may receive
									emails that include CacheTech news, updates,
									product offerings and service information,
									and marketing material. If at any time you
									would like to unsubscribe from receiving
									future emails, we include detailed
									unsubscribe instructions at the bottom of
									each email or you may contact us via the
									contact information below.
								</li>
								<li>
									<strong>
										Carry out other legitimate business
										interests:
									</strong>{" "}
									We may use the information you provide to
									carry out other legitimate business
									purposes, as well as other lawful purposes.
								</li>
							</ul>
							<p>
								We may use any aggregated information (as
								described below) for any purpose.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }} id="document3">
								How We Share Your Information
							</h4>
							<p style={{ color: "black" }}>
								We share your information with our partners,
								service providers, contractors, agents, and
								thirdparty vendors as needed to fulfill
								Services. Please note that our partners may
								contact you as necessary to obtain additional
								information about you, facilitate any use of the
								Services, or respond to a request you submit.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								Third-party vendors who provide products,
								services or functions on our behalf may include
								business analytics companies, customer service
								vendors, communications service vendors,
								marketing vendors, and security vendors. We may
								also authorize third-party vendors to collect
								information on our behalf, including as
								necessary to operate features of the Services or
								provide online advertising tailored to your
								interests. Third-party vendors have access to
								and may collect personal information only as
								needed to perform their functions, may only use
								personal information consistent with this
								Privacy Policy and other appropriate
								confidentiality and security measures, and are
								not permitted to share or use the information
								for any other purpose.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								We also may share your information:
							</p>
							<ul>
								<li>
									In response to subpoenas, court orders, or
									other legal process; to establish or
									exercise our legal rights; to defend against
									legal claims; or as otherwise required by
									law. In such cases we reserve the right to
									raise or waive any legal objection or right
									available to us.
								</li>
								<li>
									When we believe it is appropriate to
									investigate, prevent, or take action
									regarding illegal or suspected illegal
									activities; to protect and defend the
									rights, interests, or safety of our company
									or the Services, our customers, or others;
									or in connection with our Terms of Service
									and other agreements.
								</li>
								<li>
									In connection with a corporate transaction,
									such as a divestiture, merger,
									consolidation, or asset sale, or in the
									unlikely event of bankruptcy.
								</li>
							</ul>
							<p>
								Other than as set out above, we will attempt to
								notify you when your personal information will
								be shared with third parties
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<strong>
									Personal Information You Post in Public
									Areas:
								</strong>{" "}
								When you post a message in a CacheTech review or
								customer feedback, the information you post may
								be accessible to other users of the Services and
								the public. If you post personal information
								anywhere on the Services that is accessible to
								other users or the public, you are advised that
								such personal information can be read,
								collected, used, or disseminated by others and
								could be used to send you unsolicited
								information or for other purposes. Accordingly,
								you assume full risk and responsibility for
								posting such information and agree that
								CacheTech is not responsible in any way for
								personal information you choose to post in these
								public areas.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<strong>Aggregated Information:</strong> We may
								publish, share, distribute, or disclose personal
								information that has been aggregated with
								information from other users or otherwise
								de-identified data in a manner that does not
								allow third parties, including CacheTech
								partners, sponsors, and advisers, to
								de-aggregate the data and identify it as
								originating from you. Such information may help
								CacheTech identify and analyze training,
								demographic, and psychographic trends and
								information, and report to third parties how
								many people saw, visited, or clicked on certain
								content, areas of the Services, ads, or other
								materials. We may also use such data for
								research purposes and optimizing the Services’
								functionality.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<strong>Generative AI:</strong> CacheTech uses
								generative AI to assist in delivering the
								Services.{" "}
								<strong>
									However, your personal information will
									never be transferred, delivered, or made
									available to or otherwise used to train a
									generative AI product.
								</strong>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }} id="document4">
								How We Store and Secure Your Information
							</h4>
							<p style={{ color: "black" }}>
								Personal information collected by CacheTech may
								be stored and processed in your region, in the
								United States, and in any other country where
								CacheTech or its affiliates, subsidiaries, or
								service providers operate facilities. These
								countries may have data protection laws that are
								different to the laws of your country (and, in
								some cases, may not be as protective). However,
								we have taken appropriate safeguards to require
								that your personal information will remain
								protected in accordance with this Privacy
								Policy.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								CacheTech takes technological and organizational
								measures to protect your personal information
								against loss, theft, and unauthorized access,
								use, disclosure, or modification. For example:
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p>
								We rely on HubSpot’s and AWS’ security programs
								to protect personal information while stored in
								their respective controlled facilities. For more
								information on AWS’ security practices and
								processes, please see{" "}
								<a
									href="https://aws.amazon.com/security/"
									target="_blank"
									rel="noreferrer"
								>
									https://aws.amazon.com/security/
								</a>
								. For more information on HubSpot’s security
								practices and processes, please see{" "}
								<a
									href="https://legal.hubspot.com/security?hubs_content=www.hubspot.com/hubfs&hubs_contentcta=Security&_ga=2.39451747.1791479724.1707499884-
                                1401352676.1706640260&_gl=1*wrdz4c*_ga*MTQwMTM1MjY3Ni4xNzA2NjQwMjYw*_ga_LXTM6CQ0XK*MTcwNzQ5OTgyNy4yLjEuMTcwNzQ5OTg5Ny42MC4wLjA"
									target="_blank"
									rel="noreferrer"
								>
									https://legal.hubspot.com/security?hubs_content=www.hubspot.com/hubfs&hubs_contentcta=Security&_ga=2.39451747.1791479724.1707499884-
									1401352676.1706640260&_gl=1*wrdz4c*_ga*MTQwMTM1MjY3Ni4xNzA2NjQwMjYw*_ga_LXTM6CQ0XK*MTcwNzQ5OTgyNy4yLjEuMTcwNzQ5OTg5Ny42MC4wLjA
								</a>
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p>
								CacheTech complies with applicable data
								protection laws, including applicable security
								breach notification requirements
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }}>Data Retention</h4>
							<p>
								CacheTech will retain your information as long
								as necessary for the purposes outlined in this
								Privacy Policy, in a manner consistent with our
								data retention policy discussed below, and for a
								commercially reasonable time thereafter for
								backup, archival, fraud prevention or detection
								or audit purposes. CacheTech will retain your
								personal information consistent with the
								original purpose of collection or as long as
								necessary to comply with our legal obligations;
								maintain accurate accounting, financial and
								other operational records; resolve disputes; and
								enforce our agreements. We will never retain
								your information for a period longer than
								permitted by law.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p>
								We determine the appropriate retention period
								for personal information on the basis of the
								amount, nature, and sensitivity of the personal
								information being processed; the potential risk
								of harm from unauthorized use or disclosure of
								the personal information; whether we can achieve
								the purposes of the processing through other
								means; and on the basis of applicable legal
								requirements.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p>
								After expiration of the applicable retention
								periods, your personal information will be
								deleted.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }} id="document5">
								Accessing and Updating Your Information
							</h4>
							<p style={{ color: "black" }}>
								To the extent provided by the law of your
								jurisdiction, you may:
							</p>
							<ul>
								<li>
									Have the right to access certain personal
									information we maintain about you and
									request details about how we process it;
								</li>
								<li>
									Request certain information regarding our
									disclosure of personal information to third
									parties for their direct marketing purposes;{" "}
								</li>
								<li>
									Request that we update or correct
									inaccuracies regarding your personal
									information;{" "}
								</li>
								<li>
									Object to our use of your personal
									information;{" "}
								</li>
								<li>
									Ask us to block or delete your personal
									information from our database;{" "}
								</li>
								<li>
									Request to download the information you have
									shared on the Services; and
								</li>
								<li>
									Confirm whether CacheTech stores your data
									in the United States.{" "}
								</li>
							</ul>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								You may make these requests and any other
								inquiries about this Privacy Policy by emailing
								<a href="mailto:privacy@cachetech.io">
									privacy@cachetech.io
								</a>
								. Any such requests are subject to the
								protection of other individuals’ rights and
								applicable law. Additionally, to help protect
								your privacy and maintain security, we may take
								steps to verify your identity before granting
								you access to the information.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }} id="document6">
								Additional Privacy Information
							</h4>
							<p style={{ color: "black" }}>
								<strong>Data Transfer:</strong> Our databases
								are currently located in the United States.
								CacheTech makes no claim that its Services are
								appropriate or lawful for use or access outside
								the United States. If you access the Services
								from outside the United States, you are
								consenting to the transfer of your personal
								information from your location to the United
								States. You are solely responsible for complying
								with all local laws, rules and regulations
								regarding online conduct and access to the
								Services. By sending us your information, you
								further consent to its storage within the United
								States.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<strong>
									Collection of Data from Children:
								</strong>{" "}
								The Services are not directed to children, and
								you may not use the Services or provide any
								personal information to CacheTech if you are
								under the age of 16 (or the lowest age permitted
								by applicable law) or if you are not old enough
								to consent to the processing of your personal
								information in your country. CacheTech will take
								all reasonable steps necessary to protect the
								privacy and safety of any child from whom
								information is collected, as required by the
								Children’s Online Privacy Protection Act
								(COPPA). A child’s parent or guardian is
								required to provide consent before CacheTech
								collects, uses, or shares personal information
								from a child under age 13.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<strong>
									California Resident Privacy Rights:
								</strong>{" "}
								California Civil Code Section 1798.83 permits
								our customers who are California residents to
								request certain information regarding our
								disclosure of personal information to third
								parties for their direct marketing purposes. To
								make such a request, please e-mail us or write
								to us at the addresses below.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4 style={{ color: "black" }} id="document7">
								European Union, United Kingdom, and Swiss Data
								Subject Rights
							</h4>
							<p style={{ color: "black" }}>
								If you are a resident of the European Union
								(“EU”), United Kingdom (“UK”), or Switzerland,
								you may have additional rights under the EU or
								UK General Data Protection Regulation (the
								“GDPR”) or the Swiss Federal Act on Data
								Projection with respect to your Personal Data.
								The following discusses these rights
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								For this section, the GDPR definition of
								“Personal Data” and “processing” are used.
								Generally, “Personal Data” means information
								that can be used to individually identify a
								person, and “processing” relates to actions that
								can be performed in connection with collecting,
								using, storing, and disclosing such data.
								CacheTech is the controller of your Personal
								Data processed in connection with the Services
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								If a conflict exists between this this section
								and any other provision of this Privacy Policy,
								the more protective policy or portion shall
								govern to resolve such conflict. If you have any
								questions about this section or whether any of
								the following applies to you, please contact us
								at
								<a href="mailto:privacy@cachetech.io">
									privacy@cachetech.io
								</a>
								.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								We may also process Personal Data of our
								customers’ end users in connection with our
								provision of services to our customers. In this
								case, we are the processor of Personal Data. If
								you have any questions regarding Personal Data
								when CacheTech is the data processor, please
								contact the controller party in the first
								instance to address your rights with respect to
								such data.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<strong>Personal Data We Collect:</strong> The{" "}
								<strong>
									“Information We Collect from You”
								</strong>{" "}
								section above details the Personal Data that we
								collect from you.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<strong>
									Personal Data Use and Processing Grounds
								</strong>{" "}
								The “How We Use Your Information” section above
								explains how we use your Personal Data. We will
								only process your Personal Data as explained in
								this Privacy Policy and if we have a lawful
								basis for doing so. The lawful bases include
								receiving your consent, having a contractual
								necessity, and furthering our legitimate
								interests or the legitimate interest of others.
								The following sections describe these in greater
								detail.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4>Consent</h4>
							<p style={{ color: "black" }}>
								We process Personal Data based on the consent
								you expressly grant at the time we collect the
								data. When we process Personal Data based on
								your consent, it will be expressly indicated to
								you at the point and time of collection.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4>Contractual Necessity</h4>
							<p style={{ color: "black" }}>
								We may process the Personal Data as a matter of
								“contractual necessity”. This means that we need
								to process the data to perform under our
								agreement with you and enable us to provide you
								with the Services. When we process data due to
								contractual necessity, failure to provide such
								Personal Data will result in your inability to
								use some or all portions of the Services.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4>Legitimate Interest</h4>
							<p style={{ color: "black" }}>
								We process the following categories of Personal
								Data when we believe it furthers the legitimate
								interest of us or third parties providing
								products or services in connection with the
								Services:
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h4>Legitimate Interest</h4>
							<p style={{ color: "black" }}>
								We process the following categories of Personal
								Data when we believe it furthers the legitimate
								interest of us or third parties providing
								products or services in connection with the
								Services:
							</p>
							<ul>
								<li>Profile or Contact Data</li>
								<li>Demographic Data</li>
								<li>Personal Identifiers</li>
								<li>Commercial Data</li>
								<li>Device/IP Data</li>
								<li>Geolocation Data</li>
								<li>Web Analytics</li>
								<li>Financial and Payment Data</li>
								<li>Sensory Data</li>
								<li>
									Other Identifying Information that You
									Choose to Provide
								</li>
							</ul>
							<p>
								We may also de-identify or anonymize Personal
								Data to further our legitimate interests.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								Examples of these legitimate interests include
								(as described in more detail above in the “How
								We Use Your Information” section):
							</p>
							<ul>
								<li>
									Providing, customizing, and improving the
									Services.
								</li>
								<li>Marketing the Services.</li>
								<li>Corresponding with you.</li>
								<li>
									Meeting legal requirements and enforcing
									legal terms.
								</li>
								<li>Completing corporate transactions.</li>
							</ul>
							<p>Other Processing Grounds</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								Occasionally, we may also need to process
								Personal Data to comply with legal obligations,
								to protect the vital interests of you or other
								data subjects, or to carry out a task necessary
								for the public’s interest.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								Sharing Personal Data. The “How We Share Your
								Information” section above details how we share
								your Personal Data with third parties.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<h2 style={{ color: "black" }}>
								EU and UK Data Subject Rights
							</h2>
							<p style={{ color: "black" }}>
								EU and UK data subjects have certain rights with
								respect to Personal Data. This is further set
								forth below. For more information about these
								rights or to submit a request, please email us
								at privacy@cachetech.io. Please note that in
								some circumstances, CacheTech may be unable to
								fully comply with your request, including if the
								request is frivolous, extremely impractical,
								jeopardizes other’s rights, or not required by
								law. In those circumstances, we will still
								notify you of such a decision. Generally, we may
								also need you to provide us additional
								information, which may include Personal Data, if
								necessary to verify your identity and the nature
								of your request. Your rights include:
							</p>
							<ul>
								<li>
									Access: You can request information related
									to the Personal Data we store about you and
									request a copy of such Personal Data.
								</li>
								<li>
									Rectification: If you believe that any
									Personal Data we store about you is
									incorrect or incomplete, you can request we
									supplement or correct such Personal Data.
								</li>
								<li>
									Erasure: You can request we erase part or
									all of your Personal Data from our systems.
								</li>
								<li>
									Withdrawal of Consent: If we are processing
									your Personal Data based with your consent
									(as indicated at the time of collection of
									such data), you have the right to withdraw
									consent at any time. However, please note
									that if you change or withdraw consent, you
									may have to then provide express consent on
									a case-by-case basis if use or disclosure is
									necessary to enable you to use the Services.
								</li>
								<li>
									Portability: You can request for a copy of
									your Personal Data in a machine-readable
									format. You can also request we transmit the
									Personal Data to another controller to the
									extent such transmission is technically
									feasible.
								</li>
								<li>
									Objection: You can contact to object to our
									further use or disclosure of your Personal
									Data for certain purposes, such as for
									direct marketing purposes, by contacting us
									as described above.
								</li>
								<li>
									Restriction of Processing: You can request
									CacheTech to restrict further processing
									your Personal Data.
								</li>
								<li>
									Right to File Complaint: You have the right
									to submit a complaint about CacheTech's
									practices with respect to your Personal Data
									to the supervisory authority of your country
									or EU Member State. A list of Supervisory
									Authorities is available here:
									<a
										href="https://edpb.europa.eu/about-edpb/board/members_en"
										target="_blank"
										rel="noreferrer"
									>
										https://edpb.europa.eu/about-edpb/board/members_en
									</a>
								</li>
							</ul>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								Transfers of Personal Data. The “Data Transfer”
								information in the “Additional Privacy
								Information” section above details how and where
								we transfer your data. Most significantly, our
								databases are currently located in the United
								States, and you are consenting to CacheTech
								transferring your Personal Data to the United
								States.
							</p>
						</div>
					</Col>
					<Col xl={12} className="text-justify">
						<div className="section-title">
							<p style={{ color: "black" }}>
								<a href="">Click here</a> to download a copy of
								this Privacy Policy
							</p>
						</div>
					</Col>
				</Container>
			</section>
		</Fragment>
	);
};

export default PrivacyPolicy;
