import React, { Fragment, useEffect } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import CRM from "../assets/images/crm.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container, Row } from "react-bootstrap";
import ManualReview from "../assets/images/manual-review-page.png";
import ModalImage from "react-modal-image";

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'

const Rebalancing = () => {
    const slider = React.useRef(null);

    const sliderSettings = {
        infinite: false,
        autoplay: false,
        focusOnSelect: false,
        slidesToShow: 1.5,
        speed: 900,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 768, // Mobile breakpoint
                settings: {
                    slidesToShow: 1, // Show 1 slide at a time on mobile
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <Fragment>
            <CommonBanner
                bannerBg={BannerBG}
                title={
                    <Fragment>
                        <span className="colored-text"> TRADING REBALANCING </span>
                    </Fragment>
                }
                description={""}
                button={false}
                image={ManualReview}
            />

            <section className={`service-section rebalncing-section`} data-aos="fade-up" data-aos-delay="300" >
                <img className="faq-shape" src={faqShape} alt="" />

                <Container style={{ maxWidth: "1200px" }}>
                    <Row className="pb-5">
                        <Col xl={8} className="text-left">
                            <div className="section-title" data-aos="fade-right" data-aos-delay="300">
                                <h2 style={{ color: "black" }} > Optimizing Portfolios: The Art of Trading Rebalancing</h2>
                                <p style={{ color: "black" }} >
                                    Utilize our trading team at no extra cost or self-manage trades. Fast, scalable platform enables frequent rebalancing and tax loss harvesting with ease. Advanced cloud infrastructure ensures swift trading regardless of scale, powered by distributed computing.
                                </p>
                            </div>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={3} className="text-left">
                            <div className="section-title" data-aos="fade-left" data-aos-delay="300">
                                <h2 style={{ color: "black" }}>
                                    <span className="colored-text">10.5%</span>
                                </h2>
                                <p style={{ color: "black" }}>
                                    average <span className="colored-text">revenue uplift</span> for businesses using Stripe’s latest checkout optimisations
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pb-5" data-aos="fade-up" data-aos-delay="300">
                        <Col xl={8} className="text-left">
                            <div className="section-title">
                                <h4 style={{ color: "black" }}>Multiple Ways of Trading Rebalancing</h4>
                            </div>
                            <ul>
                                <li>
                                    You may utilize our trading team at no
                                    additional cost or run trading yourself.
                                </li>
                                <li>
                                    Fast and scalable allowing frequent
                                    rebalancing and tax loss harvesting.
                                    <ul>
                                        <li>
                                            Advanced cloud infrastructure
                                            and distributed computing leads
                                            to very fast trading at any
                                            scale.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <Slider ref={slider} {...sliderSettings} className="card-content" data-aos="fade-up" data-aos-delay="300">
                    <div class="card">
                        <div class="card-img">
                            <img src={ManualReview} className="modal-image" alt="" />
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-img">
                            <img src={ManualReview} className="modal-image" alt="" />
                        </div>
                    </div>
                   {/* <div class="card">
                        <div class="card-img">
                            <img src={ManualReview} className="modal-image" alt="" />
                        </div>
                    </div>  */}
                </Slider>
            </section>
        </Fragment>
    )
}

export default Rebalancing;