import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container } from "react-bootstrap";

import Support from "../Components/Support/Support";

// features images
import TeamPicture from "../assets/images/team-picture.jpg";
import ModeTrade from "../assets/images/model-trade.png";
import Restrictions from "../assets/images/restrictions-page.png";

import CRM from "../assets/images/crm.png";

const PortfolioAudit = () => {
    return (
        <Fragment>
            <CommonBanner
                bannerBg={BannerBG}
                title={
                    <Fragment>
                       <span className="colored-text"> PORTFOLIO MANAGEMENT</span>
                    </Fragment>
                }
                description={
                    <Fragment>
                    </Fragment>
                }
                button={false}
                image={ModeTrade}
            />

            <section className={`service-section`} >
                <img className="faq-shape" src={faqShape} alt="" />

                <Container style={{ maxWidth: "1200px" }}>
                    <Col xl={12} className="text-center">
                        <div className="section-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "black" }} >
                                PORTFOLIO MANAGEMENT
                            </h2>
                            <p style={{ color: "black" }} >
                        Mix and match models in accounts. Use our models and/or create your own.
					Great for optimizing asset location for tax efficiency across a household.
					Set a max tax impact to aid in transitioning a client from an undesired allocation.
                            </p>
                        </div>
                    </Col>
                </Container>
            </section>
            <section className={`service-section dark`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12} className="text-center ">
                        <div className="info-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "white" }}>CacheTech Models</h2>

                            <p style={{ color: "black" }} >
                                <Fragment>
                                </Fragment>
                            </p>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={TeamPicture}
                            desc={
                                <Fragment>
                                    <ul>
                                        <li>
                                            Passive and tactical models managed by
                                            our highly experienced investment
                                            management team at no additional charge.
                                        </li>
                                        <li>
                                            We offer model portfolios spanning the
                                            risk spectrum from conservative to
                                            aggressive.
                                        </li>
                                        <li>
                                            Multi asset class models are available
                                            as well as single asset class models.
                                            <ul>
                                                <li>
                                                    Add global diversification,
                                                    individual stock selection,
                                                    income generation, and more to
                                                    your client accounts.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Not an all or nothing approach. You can
                                            use our models alongside your own in the
                                            same account.
                                        </li>
                                    </ul>
                                </Fragment>
                            }
                        />
                    </Col>
                </Container>
            </section>
            <section className={`service-section`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12} className="text-center ">
                        <div className="info-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 className="">Your Custom Models</h2>

                            <p style={{ color: "black" }} >
                            </p>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={ModeTrade}
                            desc={
                                <Fragment>
                                    <ul>
									<li>
										Create your own models comprised of
										ETFs, mutual funds, and individual
										stocks.
									</li>
									<li>
										Easily add alternative securities for
										tax loss harvesting and client
										restrictions.
									</li>
									<li>
										Set customized rebalancing rules and
										thresholds.
									</li>
									<li>
										Analytics to provide insight into
										sector, global, and factor exposure.
									</li>
								</ul>
                                </Fragment>
                            }
                        />
                    </Col>
                </Container>
            </section>
            <section className={`service-section gradient`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12} className="text-center ">
                        <div className="info-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "white" }}>Sophisticated Restrictions</h2>

                            <p style={{ color: "black" }} >
                            </p>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={Restrictions}
                            desc={
                                <Fragment>
                                    Sophisticated restriction handling.
                                    <ul>
                                        <li>
                                            Restrict individual securities, sectors,
                                            asset classes, and more.
                                        </li>
                                        <li>
                                            Create a cash minimum restriction that
                                            ensures a minimum cash balance either
                                            permanently or just in time for periodic
                                            distributions.
                                        </li>
                                        <li>
                                            Set a max tax impact to aid in
                                            transitioning a client from an undesired
                                            allocation.
                                        </li>
                                    </ul>
                                </Fragment>
                            }
                        />
                    </Col>
                </Container>
            </section>
        </Fragment>
    )
}

export default PortfolioAudit;