import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Offcanvas, Row } from "react-bootstrap";
import Logo from "../../assets/cachetech-logo.svg";

const MobileMenu = () => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<div className="mobile-menu-wrapper">
				<div className="mobile-menu-inner">
					<Link to="/">
						<img src={Logo} alt="" />
					</Link>
					<div className="menu-trigger" onClick={handleShow}>
						<i className="fas fa-bars"></i>
					</div>
				</div>
			</div>

			<Offcanvas show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<img src={Logo} alt="" />
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<ul className="mobile-menu-items">
						<li>
							<Link to="/" onClick={() => handleClose()}>
								Home
							</Link>
						</li>
						{/* 
						<DropdownMenu
							title="Trading"
							items={[
								{ label: "Rebalancing", link: "/rebalancing" },
								{
									label: "Tax Loss Harvesting",
									link: "/tax-loss-harvesting",
								},
							]}
							closeSidebar={() => handleClose()}
						/>
						<DropdownMenu
							title="CRM"
							items={[
								{
									label: "Client Overview",
									link: "/crm-overview",
								},
								{
									label: "Tasks & Workflows",
									link: "/tasks-workflows",
								},
							]}
							closeSidebar={() => handleClose()}
						/>
						<DropdownMenu
							title="Account Management"
							items={[
								{
									label: "Portfolio Audit",
									link: "/portfolio-audit",
								},
								{ label: "Billing", link: "/billing" },
								{
									label: "Performance & Reporting",
									link: "/performance",
								},
							]}
							closeSidebar={() => handleClose()}
						/>
						<DropdownMenu
							title="Client Portals"
							items={[
								{ label: "Mobile App", link: "/mobile-app" },
								{
									label: "Document Storage",
									link: "/document-storage",
								},
								// { label: "Desktop Portal", link: "/crm-tasks" }
							]}
							closeSidebar={() => handleClose()}
						/>
						<li>
							<Link
								to="/ai-assistant"
								onClick={() => handleClose()}
							>
								AI Assistant
							</Link>
						</li>
						{/* <li>
                            <Link to="/ai-assistant" onClick={() => handleClose()}>
                                Pricing
                            </Link>
                        </li> */}
						<li>
							<a
								href="#contact-us"
								onClick={(evt) => {
									handleClose();
									const footer =
										document.getElementById("footer");
									footer.scrollIntoView({
										behavior: "smooth",
									});
								}}
							>
								Contact us
							</a>
						</li>
					</ul>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
};

export default MobileMenu;

const DropdownMenu = ({ title, items, closeSidebar }) => {
	const [dropdown, setDropdown] = useState(false);

	const handleDropdown = () => {
		setDropdown(!dropdown);
	};

	return (
		<li className="has-dropdown">
			<a href="#" onClick={handleDropdown}>
				<span>
					{title}
					<i
						className={`fas fa-angle-${dropdown ? "up" : "down"}`}
					></i>
				</span>
			</a>
			<div className={`mobile-drop-down ${dropdown ? "active" : ""}`}>
				<div className="drop-down">
					<Row>
						<Col xl={6}>
							<div className="dropdown-item">
								<ul>
									{items.map((item, index) => (
										<li key={index}>
											<Link
												to={item.link}
												onClick={closeSidebar}
											>
												<i className="fas fa-shopping-cart"></i>
												{item.label}
											</Link>
										</li>
									))}
								</ul>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</li>
	);
};
