import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import CRM from "../assets/images/crm.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container } from "react-bootstrap";

import MobileAppDemo from "../assets/images/mobile-app-demo.png";
import WebAppDemo from "../assets/images/web-app-demo.png";

import ModalImage from "react-modal-image";

const MobileApp = () => {
	return (
		<Fragment>
			<CommonBanner
				bannerBg={BannerBG}
				title={
					<Fragment>
						<span className="colored-text">MOBILE APP</span>
						{/* CLIENT PORTAL ANDROID, IOS AND WEB */}
					</Fragment>
				}
				description={
					<Fragment>
						Boost your brand seamlessly with our white-labeled
						client portal on Android, iOS, and the web. Unified
						excellence in a compact solution.
					</Fragment>
				}
				button={false}
				image={MobileAppDemo}
			/>

			<section className={`service-section`}>
				<img className="faq-shape" src={faqShape} alt="" />

				<Container style={{ maxWidth: "1200px" }}>
					<Col xl={12} className="text-center">
						<div
							className="section-title "
							data-aos="fade-up"
							data-aos-delay="300"
						>
							<h2 style={{ color: "black" }}>
								<span className="colored-text">
									FULLY WHITE LABELED{" "}
								</span>
								CLIENT PORTAL ANDROID, IOS AND WEB
							</h2>
							<p style={{ color: "black" }}>
								Unlock the power of seamless branding with our
								fully white-labeled client portal, available on
								Android, iOS, and the web. Elevate your business
								presence across platforms while providing a
								tailored and consistent user experience. Embrace
								a unified solution for client engagement that
								reflects your brand identity.
							</p>
						</div>
					</Col>
					<Col xl={12}>
						<div
							className="info-title "
							data-aos="fade-up"
							data-aos-delay="300"
						>
							<h5>Devices</h5>
						</div>
					</Col>
				</Container>
			</section>
			<section
				className={`service-section gradient`}
				data-aos="fade-up"
				data-aos-delay="300"
			>
				<Container>
					<Col xl={12}>
						<div
							className="section-title "
							data-aos="fade-up"
							data-aos-delay="300"
						>
							<h2 className="">Mobile App</h2>
						</div>
					</Col>
					<Col xl={12}>
						<ModalImage
							small={MobileAppDemo}
							medium={MobileAppDemo}
							// alt={description}
							className="modal-image"
						/>
					</Col>
				</Container>
			</section>
			<section
				className={`service-section`}
				data-aos="fade-up"
				data-aos-delay="300"
			>
				<Container>
					<Col xl={12}>
						<div
							className="info-title "
							data-aos="fade-up"
							data-aos-delay="300"
						>
							<h2 className="">Web App</h2>
						</div>
					</Col>
					<Col xl={12}>
						<ModalImage
							small={WebAppDemo}
							medium={WebAppDemo}
							// alt={description}
							className="modal-image"
						/>
					</Col>
				</Container>
			</section>
		</Fragment>
	);
};

export default MobileApp;
