import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import CRM from "../assets/images/crm.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import CaitVideo from "../assets/videos/cait-demo.mp4";
import IPSVideo from "../assets/videos/cait-ips-demo.mp4";
import DocumentsVideo from "../assets/videos/cait-documents-demo.mp4";

const AiSupport = () => {
    return (
        <Fragment>
            <CommonBanner
                bannerBg={BannerBG}
                title={
                    <Fragment>
                        Your AI Assistant <span className="colored-text"> CAIT </span>
                    </Fragment>
                }
                description={
                    <Fragment>
                        Cait, your personal AI advisor, transforms client interactions by offering conversational access to data, enhancing efficiency for advisors to prioritize strong relationships and personalized service.
                    </Fragment>
                }
                button={false}
                image={CRM}
            />

            <section className={`service-section`}  data-aos="fade-up" data-aos-delay="300">
                <img className="faq-shape" src={faqShape} alt="" />

                <Container style={{ maxWidth: "1200px" }}>
                    <Col xl={12} className="text-center">
                        <div className="section-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "black" }} >
                                INTRODUCING <span className="colored-text"> CAIT </span>
                                YOUR PERSONAL
                                <br />
                                AI ADVISOR SUPPORT
                            </h2>
                            <p style={{ color: "black" }} >
                                At the core of our solution is Cait, your personal AI advisor support. Cait enables users to engage with data in a conversational manner, including client information, account balances, transactions, and investment performance. This intuitive AI interface enhances efficiency and allows advisors to focus on building strong client relationships and delivering personalized service.
                            </p>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <div className="info-title " data-aos="fade-up" data-aos-delay="300">
                            <h5>Supports</h5>
                        </div>
                    </Col>
                </Container>
            </section>
            <section className={`service-section dark`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Row className="pb-5">
                        <Col xl={4}>
                            <div className="info-title wow fadeInLeft ">
                                <h2 style={{color: "white"}}>Your Personal AI Advisor Support</h2>

                                {/* <p style={{ color: "black" }} >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p> */}
                            </div>
                        </Col>
                        <Col xl={8}>
                            <div className="play-section wow fadeInRight">
                                <ReactPlayer
                                    url={CaitVideo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    width="calc(100%)"
                                    height="calc(100%)"
                                    style={{ width: "fit-content" }}
                                    controls={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={`service-section light`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Row className="pb-5">
                        <Col xl={8}>
                            <div className="play-section wow fadeInLeft">
                                <ReactPlayer
                                    url={IPSVideo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    width="calc(100%)"
                                    height="calc(100%)"
                                    style={{ width: "fit-content" }}
                                    controls={true}
                                />
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="info-title wow fadeInRight">
                                <h2 className="">Investment Policy Statement by CAIT</h2>

                                {/* <p style={{ color: "black" }} >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={`service-section gradient`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Row>
                        <Col xl={4}>
                            <div className="info-title wow fadeInLeft">
                                <h2 style={{color: "white"}}>Document processing and insights from CAIT</h2>

                                {/* <p style={{ color: "black" }} >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p> */}
                            </div>
                        </Col>
                        <Col xl={8}>
                            <div className="play-section wow fadeInRight">
                                <ReactPlayer
                                    url={DocumentsVideo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    width="calc(100%)"
                                    height="calc(100%)"
                                    style={{ width: "fit-content" }}
                                    controls={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default AiSupport;