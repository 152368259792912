import React from "react";
import { Col, Container } from "react-bootstrap";
import AutoChangingTab from "../AutoChangingTab/AutoChangingTab";
import ServiceShape from "./service-bg.jpg";
import faqShape from "./faq-shape.png";
import SupportShape from "./support-shape.png";

const Services = (props) => {
	const {
		innerRef,
		sectionName,
		title,
		description,
		tabs,
		bgColor = "dark",
		activeTab,
	} = props;

	const getBackgroundColor = () => {
		if (bgColor === "gradient") {
			return "gradient";
		} else if (bgColor === "light") {
			return "light";
		} else {
			return "dark";
		}
	};

	const getImageFeature = () => {
		if (bgColor === "light") {
			return (
				<>
					<img className="faq-shape" src={faqShape} alt="" />
				</>
			);
		} else if (bgColor === "gradient") {
			return (
				<>
					<img className="service-img" src={SupportShape} alt="" />
				</>
			);
		}
		return (
			<>
				<img className="service-shape" src={ServiceShape} alt="" />
			</>
		);
	};

	return (
		<section
			ref={innerRef}
			className={`service-section ${getBackgroundColor()}`}
		>
			{getImageFeature()}
			<Container style={{ maxWidth: "1200px" }}>
				<Col xl={12} className="text-center">
					<div className="section-title wow fadeInUp">
						{title && (
							<h2
								style={{
									color:
										bgColor === "light" ? "black" : "white",
								}}
							>
								{title}
							</h2>
						)}
						{description && (
							<p
								style={{
									color:
										bgColor === "light" ? "black" : "white",
								}}
							>
								{description}
							</p>
						)}
					</div>
				</Col>
				{tabs && tabs.length > 0 && (
					<AutoChangingTab
						sectionName={sectionName}
						tabs={tabs}
						activeTabName={activeTab}
						bgColor={bgColor}
					/>
				)}
			</Container>
		</section>
	);
};

export default Services;
