import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WOW from "wowjs";
import HomePage from "./Pages/HomePage";
import ErrorPage from "./Pages/ErrorPage";
import DownloadPage from "./Pages/DownloadPage";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

// products routes component
import Rebalancing from "./Pages/Rebalancing";
import TaxLossHarvesting from "./Pages/TaxLossHarvesting";

import CrmOverview from "./Pages/CrmOverview";
import TasksWorkflows from "./Pages/TasksWorkflows";

import PortfolioAudit from "./Pages/PortfolioAudit";
import Billing from "./Pages/Billing";
import Performance from "./Pages/Performance";

import AiSupport from "./Pages/AiSupport";
import MobileApp from "./Pages/MobileApp";
import DocumentStorage from "./Pages/DocumentStorage";

import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "./ScrollToTop";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

const App = () => {
	useEffect(() => {
		new WOW.WOW({
			mobile: false,
		}).init();

		AOS.init({
			mobile: false,
		});
	}, []);


	useEffect(() => {
		// Check if the URL contains a hash
		if (window.location.hash) {
			// Get the element with the corresponding ID
			const targetElement = document.querySelector(window.location.hash);

			const headerHeight = document.querySelector('header').offsetHeight;

			// Scroll to the element if it exists
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offset: -headerHeight });
			}
		}
	}, []);

	return (
		<Router>
			<div className="App">
				<Header />
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/rebalancing" element={<Rebalancing />} />
					<Route path="/tax-loss-harvesting" element={<TaxLossHarvesting />} />

					<Route path="/crm-overview" element={<CrmOverview />} />
					<Route path="/tasks-workflows" element={<TasksWorkflows />} />

					<Route path="/portfolio-audit" element={<PortfolioAudit />} />
					<Route path="/billing" element={<Billing />} />
					<Route path="/performance" element={<Performance />} />

					<Route path="/mobile-app" element={<MobileApp />} />
					<Route path="/document-storage" element={<DocumentStorage />} />

					<Route path="/ai-assistant" element={<AiSupport />} />
					<Route path="*" element={<ErrorPage />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
};

export default App;
