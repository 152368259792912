import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container } from "react-bootstrap";

import Support from "../Components/Support/Support";

// features images
import SecuritiesPage from "../assets/images/securities.png";
import PerformanceImg from "../assets/images/hh-performance.png";
import ModelPerformance from "../assets/images/model-performance.png";

import CRM from "../assets/images/crm.png";

const Performance = () => {
    return (
        <Fragment>
            <CommonBanner
                bannerBg={BannerBG}
                title={
                    <Fragment>
                       <span className="colored-text"> PERFORMANCE AND REPORTING</span>
                    </Fragment>
                }
                description={
                    <Fragment>
                    </Fragment>
                }
                button={false}
                image={PerformanceImg}
            />

            <section className={`service-section`} >
                <img className="faq-shape" src={faqShape} alt="" />

                <Container style={{ maxWidth: "1200px" }}>
                    <Col xl={12} className="text-center">
                        <div className="section-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "black" }} >
                            PERFORMANCE AND REPORTING
                            </h2>
                            <p style={{ color: "black" }} >
                            Quickly access performance and reporting for individual accounts, households and models. Create custom reports and dashboards to meet your needs.
                            </p>
                        </div>
                    </Col>
                </Container>
            </section>
            <section className={`service-section dark`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12}>
                        <div className="info-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{color: "white"}}>Client Performance</h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={PerformanceImg}
                        />
                    </Col>
                </Container>
            </section>
            <section className={`service-section`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12}>
                        <div className="info-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 className="">Model Performance</h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={ModelPerformance}
                        />
                    </Col>
                </Container>
            </section>
            <section className={`service-section gradient`}  data-aos="fade-up" data-aos-delay="300">
                <Container>
                    <Col xl={12}>
                        <div className="info-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{color: "white"}}>Reporting</h2>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={SecuritiesPage}
                        />
                    </Col>
                </Container>
            </section>
        </Fragment>
    )
}

export default Performance;