import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import FooterLogo from "../../assets/cachetech-logo.svg";
import ContactUs from "../Forms/ContactUs";
import pdfFile from "../../assets/documents/ADV2A.pdf";

const Footer = () => {
	const location = useLocation();

	if (location.pathname === "/privacy-policy") {
		return null;
	}
	const handleClickEmail = () => {
		window.open(`mailto:support@cachetech.io`);
	};
	const handleClickPhone = () => {
		window.open(
			"https://www.google.com/search?q=cachetech+logan&sca_esv=d690447d4819ce9a&sca_upv=1&rlz=1C5CHFA_enUS1040US1041&sxsrf=ACQVn0902bc26n3KugsYjXPoSu3ObYLhBw%3A1709569521930&ei=8fXlZY2wOM3tptQPlbaIsAY&ved=0ahUKEwjN7MrggtuEAxXNtokEHRUbAmYQ4dUDCBA&uact=5&oq=cachetech+logan&gs_lp=Egxnd3Mtd2l6LXNlcnAiD2NhY2hldGVjaCBsb2dhbjIFECEYoAEyBRAhGKABMgUQIRigATIFECEYoAFIoB1QoQNYzxpwAXgAkAEAmAFkoAH5CKoBBDE0LjG4AQPIAQD4AQGYAhCgAqsJwgILEAAYiQUYogQYsAPCAgQQABgewgIHEAAYgAQYCsICBxAuGIAEGArCAg0QABiABBgKGLEDGLEDwgINEC4YgAQYChjHARivAcICChAAGIAEGAoYsQPCAg0QLhiABBjHARjRAxgKwgILEC4YgAQYxwEYrwHCAgUQABiABMICBRAuGIAEwgINEC4YgAQYChixAxjUAsICHBAuGIAEGMcBGNEDGAoYlwUY3AQY3gQY4ATYAQHCAgoQIxiABBiKBRgnwgILEAAYgAQYigUYkQLCAggQABiABBixA8ICCBAuGIAEGLEDmAMAiAYBkAYBugYGCAEQARgUkgcEMTUuMQ&sclient=gws-wiz-serp#",
			"_blank"
		);
	};

	const handleDownloadAndOpen = () => {
		const link = document.createElement("a");
		link.href = pdfFile;
		link.download = "ADV2A.pdf";
		link.target = "_blank";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<footer style={{ paddingTop: "3rem" }} id="footer">
			<div className="site-footer">
				<Container>
					<Row>
						<Col xl={4} lg={4} md={4}>
							<div className="footer-logo-container">
								<img src={FooterLogo} alt="" width="150rem" />
							</div>
							<div className="footer-box footer-social">
								<ul className="ul-social">
									<li>
										<a
											href="https://www.linkedin.com/company/cachetech-ria"
											target="_blank"
											rel="noreferrer"
										>
											<i className="fab fa-linkedin-in"></i>
										</a>
									</li>
								</ul>
							</div>
							<div className="footer-box footer-social info-block">
								<ul className="ul-social">
									<li style={{ color: "white" }}>
										<p>
											<i className="fas fa-map-marker-alt"></i>
											<span>
												701 Main St, Logan, UT 84321
											</span>
										</p>
										<p>
											<i className="fas fa-envelope"></i>
											<span
												className="info-block-link"
												onClick={handleClickEmail}
											>
												support@cachetech.io
											</span>
										</p>
										<p>
											<i className="fas fa-phone"></i>
											<span
												className="info-block-link"
												onClick={handleClickPhone}
											>
												(435) 752-1702
											</span>
										</p>
									</li>
								</ul>
							</div>
						</Col>
						<Col xl={8} lg={8} md={8}>
							<ContactUs />
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<p className="text-white">
								CacheTech is a registered investment adviser.
								Information presented is for educational
								purposes only and does not intend to make an
								offer or solicitation for the sale or purchase
								of any specific securities, investments, or
								investment strategies. Investments involve risk
								and, unless otherwise stated, are not
								guaranteed. Be sure to first consult with a
								qualified financial adviser and/or tax
								professional before implementing any strategy
								discussed herein. Past performance is not
								indicative of future performance.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="copyright-section">
				<Container>
					<Row className="align-items-center">
						<Col xl={6} lg={6}>
							<div className="copyright-left">
								<p>
									&copy; {new Date().getFullYear()}{" "}
									<strong>CacheTech</strong>
								</p>
							</div>
						</Col>
						<Col xl={6} lg={6}>
							<div className="copyright-right text-end">
								<ul>
									<li>
										<Link
											to=""
											onClick={handleDownloadAndOpen}
										>
											ADV 2A
										</Link>
									</li>
									<li>
										<Link to="/privacy-policy">
											Privacy
										</Link>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
