import React, { useState, useEffect, useRef } from "react";
import { Form, Alert, Dropdown, Row, Col } from "react-bootstrap";
import axios from "axios";

import { INTERESTS_OPTIONS, CONTACT_US_URL } from "../../constants";

const ContactUs = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		interests: [],
		message: "",
	});

	const [formErrors, setFormErrors] = useState({
		firstName: "",
		lastName: "",
		email: "",
	});
	const [confirmationMessage, setConfirmationMessage] = useState([]);
	const formRef = useRef(null);

	useEffect(() => {
		const script = document.createElement("script");
		script.charset = "utf-8";
		script.type = "text/javascript";
		script.src = "//js.hsforms.net/forms/embed/v2.js";
		document.body.appendChild(script);
		script.onload = () => {
			console.log("script loaded");
			window.hbspt.forms.create({
				region: "na1",
				portalId: "43636755",
				formId: "5ec5df31-90c1-4500-99cb-6652fdcd0fb2",
				target: "#ct-hb-form",
			});
		};

		return () => {
			document.body.removeChild(script);
		};
	}, [formRef, formRef.current]);

	const handleInputChange = (event) => {
		const { name, value, type, checked } = event.target;
		if (type === "checkbox") {
			const updatedInterests = checked
				? [...formData.interests, value]
				: formData.interests.filter((interest) => interest !== value);

			setFormData({
				...formData,
				interests: updatedInterests,
			});
		} else {
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	const postData = (data) => {
		const headers = { "Content-Type": "application/json" };
		axios
			.post(CONTACT_US_URL, JSON.stringify(data), { headers })
			.then(function (response) {
				setFormData({
					firstName: "",
					lastName: "",
					email: "",
					interests: [],
					message: "",
				});
				setConfirmationMessage(["Successfully submitted!", "success"]);
			})
			.catch(function (error) {
				console.error(error);
				setConfirmationMessage([
					"Something went wrong. Please try again later.",
					"danger",
				]);
			});
	};

	const getFormErrors = () => {
		const errors = {};
		if (!formData.firstName) {
			errors.firstName = "First Name is required";
		}
		if (!formData.lastName) {
			errors.lastName = "Last Name is required";
		}
		if (!formData.email) {
			errors.email = "Email is required";
		}
		// Check if email valid
		if (
			formData.email &&
			!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
		) {
			errors.email = "Email is invalid";
		}
		return errors;
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const errors = getFormErrors();
		if (Object.keys(errors).length === 0) {
			// Handle form submission here
			postData(formData);
			setFormErrors({
				firstName: "",
				lastName: "",
				email: "",
			});
		} else {
			setFormErrors(errors);
		}
	};

	const disableSubmit = () => {
		return !formData.firstName || !formData.lastName || !formData.email;
	};

	return (
		<div className="contact-us-container" id="contact-us">
			<h2>Contact Us</h2>
			{confirmationMessage[0] && (
				<Alert variant={confirmationMessage[1]}>
					{confirmationMessage[0]}
				</Alert>
			)}
			<div ref={formRef} id="ct-hb-form"></div>
			{/* <Form onSubmit={handleSubmit}>
				<Row>
					<Col md={6}>
						<Form.Group controlId="firstName">
							<Form.Label className="contact-us-form-label">
								First Name *
							</Form.Label>
							<Form.Control
								type="text"
								name="firstName"
								value={formData.firstName}
								onChange={handleInputChange}
								isInvalid={!!formErrors.firstName}
							/>
							<Form.Control.Feedback type="invalid">
								{formErrors.firstName}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId="lastName">
							<Form.Label className="contact-us-form-label">
								Last Name *
							</Form.Label>
							<Form.Control
								type="text"
								name="lastName"
								value={formData.lastName}
								onChange={handleInputChange}
								isInvalid={!!formErrors.lastName}
							/>
							<Form.Control.Feedback type="invalid">
								{formErrors.lastName}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId="email">
							<Form.Label className="contact-us-form-label">
								Email *
							</Form.Label>
							<Form.Control
								type="email"
								name="email"
								value={formData.email}
								onChange={handleInputChange}
								isInvalid={!!formErrors.email}
							/>
							<Form.Control.Feedback type="invalid">
								{formErrors.email}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId="interests">
							<Form.Label className="contact-us-form-label">
								What are you interested in?
							</Form.Label>
							<Dropdown>
								<Dropdown.Toggle
									variant="secondary"
									id="interestsDropdown"
									className="contact-us-form-dropdown"
								>
									Select Interests
								</Dropdown.Toggle>
								<Dropdown.Menu className="contact-us-form-menu">
									{INTERESTS_OPTIONS.map((option) => (
										<Form.Check
											key={option}
											type="checkbox"
											label={option}
											name="interests"
											value={option}
											onChange={handleInputChange}
										/>
									))}
								</Dropdown.Menu>
							</Dropdown>
						</Form.Group>
					</Col>
				</Row>

				<Form.Group controlId="message">
					<Form.Label className="contact-us-form-label">
						Message
					</Form.Label>
					<Form.Control
						as="textarea"
						rows={3}
						name="message"
						value={formData.message}
						onChange={handleInputChange}
					/>
				</Form.Group>
				<button
					className="contact-us-form-button"
					type="submit"
					disabled={disableSubmit()}
					style={{
						opacity: disableSubmit() ? 0.3 : 1,
						color: disableSubmit() ? "black" : "white",
						cursor: disableSubmit() ? "" : "pointer",
					}}
				>
					Submit
				</button>
			</Form> */}
		</div>
	);
};

export default ContactUs;
