import { Fragment } from "react";
import CommonBanner from "../Components/Banner/CommonBanner";
import BannerBG from "../assets/images/banner-bg.png";
import faqShape from "../assets/images/faq-shape.png";
import { Col, Container } from "react-bootstrap";

import Support from "../Components/Support/Support";

// features images
import BillingImg from "../assets/images/billing.png";

import CRM from "../assets/images/crm.png";

const Billing = () => {
    return (
        <Fragment>
            <CommonBanner
                bannerBg={BannerBG}
                title={
                    <Fragment>
                       <span className="colored-text"> BILLING</span>
                    </Fragment>
                }
                description={
                    <Fragment>
                    </Fragment>
                }
                button={false}
                image={BillingImg}
            />

            <section className={`service-section dark}`} >
                <img className="faq-shape" src={faqShape} alt="" />

                <Container style={{ maxWidth: "1200px" }}>
                    <Col xl={12} className="text-center">
                        <div className="section-title " data-aos="fade-up" data-aos-delay="300">
                            <h2 style={{ color: "black" }} >
                                Billing
                            </h2>
                            <p style={{ color: "black" }} >
                                We can run your billing or train your staff to use our flexible billing solution that is customizable to any billing methodology. Easily create new billing, pull historical billing, and build forecasts. 
                            </p>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Support
                            imgSrc={BillingImg}
                        />
                    </Col>
                </Container>
            </section>
        </Fragment>
    )
}

export default Billing;