import React, { useState } from "react";

import ModalImage from "react-modal-image";

const Support = (props) => {
	const { imgSrc, desc } = props;

	const [hover, setHover] = useState(false);

	const isMobile = window.innerWidth < 991;

	return (
		<section className="support-section wow fadeInUP">
			<div
				className="fadeInDown"
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{/* <img src={imgSrc} alt="" style={{ borderRadius: "10px" }} /> */}
				<ModalImage
					small={imgSrc}
					medium={imgSrc}
					// alt={desc}
					className="modal-image"
				/>
			</div>
			<div
				className="tab-description"
				style={{ opacity: hover || isMobile ? 1 : 0 }}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{desc}
			</div>
		</section>
	);
};

export default Support;
